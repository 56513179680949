import styled, { keyframes } from 'styled-components';

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FindContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FindWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 64px 24px;
  }

  @media (min-width: 1024px) {
    padding: 64px 80px;
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  max-width: 1024px;

  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 72px;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    column-gap: 120px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media (min-width: 1024px) {
    &.--first {
      width: 300px;
    }

    &.--second {
      flex-grow: 1;
      max-width: 604px;
    }
  }

  h1 {
    width: fit-content;
  }
`;

export const InputField = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: 100%;

  & > .--autocomplete {
    flex-grow: 1;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 16px;
  color: #fff;
  background-color: transparent;
  font-family: inherit;
  font-size: 13px;
  letter-spacing: 0.13px;
  border: 1px solid #595a5b;
  outline: none;
  transition: all 0.3s;

  &::placeholder {
    color: #595a5b;
  }

  &:hover,
  &:focus {
    border: 1px solid #fff;
  }
`;

export const MapContainer = styled.div`
  position: relative;

  .mapContainer {
    @media (max-width: 1023px) {
      height: 480px;

      &.--isShowList {
        display: none;
      }
    }

    @media (min-width: 1024px) {
      height: 800px;

      &.--isShowList {
        width: calc(100% - 600px);
      }
    }
  }

  .gm-style .gm-style-iw-c {
    padding: 0;
    background-color: #272727;
    border-radius: 0;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-tc {
    filter: none;

    &::after {
      background-color: #272727;
    }
  }

  button.gm-ui-hover-effect {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 32px !important;
    height: 32px !important;

    & > span {
      margin: 0 !important;
      mask-image: none !important;
      background-color: transparent !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='18px' height='18px'%3E%3Cpath fill='%23ffffff' d='M12.853 12.146a.506.506 0 0 1 0 .708.495.495 0 0 1-.354.146.504.504 0 0 1-.353-.146L7.999 8.707l-4.146 4.147a.504.504 0 0 1-.707 0 .5.5 0 0 1 0-.708L7.293 8 3.146 3.854a.5.5 0 1 1 .707-.708l4.146 4.147 4.147-4.147a.497.497 0 0 1 .707 0 .5.5 0 0 1 0 .708L8.706 8l4.147 4.146Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  column-gap: 32px;
`;

export const ToggleList = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  color: #000;
  background-color: #fff;
  border: 0 none;
  cursor: pointer;

  @media (max-width: 1023px) {
    top: 16px;
    right: 16px;

    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    top: 24px;
    right: 24px;

    &.--mobile {
      display: none;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #000;

  @media (max-width: 1023px) {
    width: 100%;
    min-height: 480px;
    padding: 40px 24px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 600px;
    padding: 40px 56px 0 80px;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: fit-content;
  color: #919ca9;
`;

export const SelectedFilter = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.13px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding-right: 24px;
  }
`;

export const MarkerPopup = styled.div`
  /* position: absolute; */
  /* inset: 0; */
  background-color: #272727;
`;

export const MarkerPopupContainer = styled.div`
  width: 220px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
`;

export const MarkerPopupTitle = styled.div`
  margin-bottom: 8px;
  color: #e88b53;
  font-family: 'Evanston Alehouse 1826 Light';
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
`;

export const MarkerPopupAddress = styled.div`
  color: #adadad;
  font-family: 'Esteban';
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.13px;
`;

export const MarkerPopupActions = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: 12px;
`;

export const MarkerPopupAction = styled.a`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #fff;
  text-align: center;
  font-family: 'Esteban';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.84px;
  text-transform: capitalize;
`;

export const PreloaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Preloader = styled.div`
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
