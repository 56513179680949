import { useState, useRef, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useJsApiLoader,
  GoogleMap,
  Autocomplete,
  Marker,
} from '@react-google-maps/api';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useGeolocated } from 'react-geolocated';

import {
  Root,
  FindContainer,
  FindWrapper,
  Form,
  FormItem,
  InputField,
  Input,
  Options,
  MapContainer,
  ToggleList,
  ListContainer,
  ListHeader,
  SelectedFilter,
  List,
  PreloaderContainer,
  Preloader,
} from './Find.style';

import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { MapListItem } from '../MapListItem/MapListItem';
import { InfoWindow } from './InfoWindow';

const libraries = ['places', 'geometry'];
const initialCenter = { lat: 35.71142, lng: -83.51986 };
const options = { componentRestrictions: { country: 'us' } };

export const Find = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 0,
    });

  const prevSearchParamsRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const rootRef = useRef(null);
  const inputRef = useRef(null);
  const mapRef = useRef(null);
  const [center, setCenter] = useState(initialCenter);

  const [inputValue, setInputValue] = useState('');
  const [locationFilterValues, setLocationFilterValues] = useState({
    retail: true,
    barsAndRestaurants: false,
  });

  const [locations, setLocations] = useState([]);
  const [markerIcon, setMarkerIcon] = useState(null);
  const [markerIconActive, setMarkerIconActive] = useState(null);
  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowList, setIsShowList] = useState(true);

  const locationFilter = `${locationFilterValues.retail ? 'on' : 'off'},${
    locationFilterValues.barsAndRestaurants ? 'on' : 'off'
  }`;

  useEffect(() => {
    const queryParam = searchParams.get('q');

    if (queryParam) {
      if (prevSearchParamsRef.current !== searchParams) {
        setInputValue(queryParam);
        fetchGeocodeForAddress(queryParam);
      }
    } else {
      if (coords) {
        const { latitude, longitude } = coords;
        const coordinates = {
          latitude,
          longitude,
          territory: 3,
          locationFilter,
        };
        fetchLocations({ coordinates });
      }
    }

    prevSearchParamsRef.current = searchParams;
  }, [searchParams, coords]);

  const handleInputChange = () => {
    const place = inputRef.current.getPlace();
    if (place && place.geometry) {
      const location = place.geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      setCenter({ lat, lng });

      const coordinates = {
        latitude: lat,
        longitude: lng,
        territory: 3,
        locationFilter,
      };

      fetchLocations({ coordinates });

      if (place.formatted_address) {
        setSearchParams(
          {
            q: place.formatted_address,
            locationFilter,
          },
          { preventScrollReset: true }
        );
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const newLocationFilterValues = {
      ...locationFilterValues,
      [name]: checked,
    };
    const newLocationFilter = `${
      newLocationFilterValues.retail ? 'on' : 'off'
    },${newLocationFilterValues.barsAndRestaurants ? 'on' : 'off'}`;

    setLocationFilterValues(newLocationFilterValues);

    setSearchParams(
      {
        q: inputValue,
        locationFilter: newLocationFilter,
      },
      { preventScrollReset: true }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchGeocodeForAddress(inputValue);
    setSearchParams(
      {
        q: inputValue,
        locationFilter,
      },
      { preventScrollReset: true }
    );
  };

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;

    const pin = {
      url:
        'data:image/svg+xml;charset=UTF-8,' +
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px">
            <path fill="#DB8F5E" d="M11.999 2.5A7.4 7.4 0 0 0 6.76 4.689a7.5 7.5 0 0 0-2.175 5.275c0 2.664 1.223 5.486 3.539 8.165a21.417 21.417 0 0 0 3.493 3.248.667.667 0 0 0 .772 0 21.453 21.453 0 0 0 3.486-3.248c2.312-2.679 3.539-5.501 3.539-8.165a7.499 7.499 0 0 0-2.174-5.275A7.404 7.404 0 0 0 11.999 2.5Z"/>
            <path fill="#fff" d="m12.013 7.2 2.807 2.879-2.807 2.88-2.807-2.88L12.013 7.2Z"/>
          </svg>
        `),
      scaledSize: new window.google.maps.Size(48, 48),
    };
    const pinActive = {
      url:
        'data:image/svg+xml;charset=UTF-8,' +
        encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px">
        <path fill="#DB8F5E" d="m8.499 17.82-.001-.001c-2.255-2.618-3.406-5.334-3.406-7.851a7.044 7.044 0 0 1 2.027-4.944 6.872 6.872 0 0 1 4.88-2.049 6.875 6.875 0 0 1 4.882 2.049 7.046 7.046 0 0 1 2.027 4.944c0 2.517-1.155 5.233-3.406 7.852l-.001.001a20.887 20.887 0 0 1-3.383 3.169.207.207 0 0 1-.23 0 20.888 20.888 0 0 1-3.389-3.17Z"/>
        <path fill="#fff" d="m12.013 7.207 2.789 2.874-2.789 2.874-2.789-2.874 2.789-2.874ZM8.162 18.156l-.024-.029c-2.294-2.678-3.502-5.549-3.521-8.16a7.804 7.804 0 0 1 2.164-5.276A7.63 7.63 0 0 1 12 2.5a7.636 7.636 0 0 1 5.219 2.19 7.808 7.808 0 0 1 2.164 5.278c-.019 2.611-1.256 5.507-3.545 8.188l.024-.027a21.858 21.858 0 0 1-3.481 3.256 1.005 1.005 0 0 1-.378.115c-.111 0-.303-.077-.399-.129a21.428 21.428 0 0 1-3.442-3.215Zm3.842 2.327a20.036 20.036 0 0 0 3.136-2.972l.026-.028c2.212-2.555 3.286-5.091 3.267-7.515.016-1.765-.644-3.376-1.89-4.609-1.219-1.26-2.805-1.925-4.544-1.909-1.737-.016-3.323.649-4.542 1.907a6.311 6.311 0 0 0-1.89 4.612c-.019 2.415 1.068 4.968 3.269 7.514a20.351 20.351 0 0 0 3.168 3Zm-3.168-3h-.001l.023.026-.022-.026Z"/>
      </svg>
    `),
      scaledSize: new window.google.maps.Size(48, 48),
    };

    setMarkerIcon(pin);
    setMarkerIconActive(pinActive);

    const coordinatesUpdate = () => {
      const center = map.getCenter();
      const bounds = map.getBounds();
      const cornerCoordinates = bounds.getNorthEast();
      const distanceMeters =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          center,
          cornerCoordinates
        );
      const distanceMiles = 0.000621371 * distanceMeters;
      const coordinates = {
        latitude: center.lat(),
        longitude: center.lng(),
        distance: distanceMiles,
        territory: 3,
        locationFilter,
      };

      fetchLocations({ coordinates });
    };

    map.addListener('dragend', coordinatesUpdate);
  }, []);

  const fetchLocations = async ({ coordinates }) => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_MOONSHINE_API}/locator`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ coordinates }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const updatedLocations = data?.location;

      if (updatedLocations.length > 0) {
        setLocations(updatedLocations);
        const bounds = new window.google.maps.LatLngBounds();
        updatedLocations.forEach((loc) => {
          bounds.extend(new window.google.maps.LatLng(loc.lat, loc.long));
        });
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds);
        }
      } else {
        setCenter({ lat: coordinates.latitude, lng: coordinates.longitude });
        // TODO: show no results overlay
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGeocodeForAddress = async (address) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_MOONSHINE_API}/geocode`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ address }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const gps = data?.results?.[0]?.geometry?.location;

      console.info(data.status);

      if (gps) {
        setCenter({
          lat: gps.lat,
          lng: gps.lng,
        });

        const coordinates = {
          latitude: gps.lat,
          longitude: gps.lng,
          territory: 3,
          locationFilter,
        };

        fetchLocations({ coordinates });
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleScroll = () => {
    if (rootRef.current) {
      rootRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!isGeolocationAvailable) {
    console.info('Your browser does not support Geolocation');
  }
  if (!isGeolocationEnabled) {
    console.info('Geolocation is not enabled');
  }

  return (
    <Root ref={rootRef}>
      <FindContainer>
        <FindWrapper>
          <Form onSubmit={handleSubmit}>
            {/* <FormItem className="--first">
              <h1>buy online</h1>
              <Button
                as="a"
                href="https://olesmoky.com/collections/popcorn-sutton"
              >
                Shop Popcorn Sutton
              </Button>
            </FormItem> */}

            <FormItem className="--second">
              <h1>find near you</h1>
              <InputField>
                {isLoaded ? (
                  <Autocomplete
                    className="--autocomplete"
                    onLoad={(autocomplete) => (inputRef.current = autocomplete)}
                    onPlaceChanged={handleInputChange}
                    options={options}
                  >
                    <Input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Your location"
                    />
                  </Autocomplete>
                ) : (
                  <Input placeholder="Your location" disabled />
                )}
                <Button type="submit" disabled={!inputValue}>
                  Search
                </Button>
              </InputField>
              <Options>
                <Checkbox
                  name="retail"
                  label="Retail"
                  checked={locationFilterValues.retail}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  name="barsAndRestaurants"
                  label="Bars & Restaurants"
                  checked={locationFilterValues.barsAndRestaurants}
                  onChange={handleCheckboxChange}
                />
              </Options>
            </FormItem>
          </Form>
        </FindWrapper>
      </FindContainer>

      <MapContainer>
        {isLoaded && (
          <GoogleMap
            id="map"
            mapContainerClassName={`mapContainer${
              isShowList ? ' --isShowList' : ''
            }`}
            options={{
              mapId: 'b5744eebd4b2e573',
              disableDefaultUI: true,
              zoomControl: true,
            }}
            center={center}
            zoom={8}
            onLoad={handleMapLoad}
          >
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(location.lat),
                  lng: parseFloat(location.long),
                }}
                icon={
                  location.num === activeMarkerId
                    ? markerIconActive
                    : markerIcon
                }
                onClick={() => {
                  setSelectedMarker(location);
                  setActiveMarkerId(location.num);
                }}
              />
            ))}

            {selectedMarker && (
              <InfoWindow
                selectedMarker={selectedMarker}
                onClose={() => {
                  setSelectedMarker(null);
                  setActiveMarkerId(null);
                }}
              />
            )}
          </GoogleMap>
        )}

        {isLoading && (
          <PreloaderContainer>
            <Preloader />
          </PreloaderContainer>
        )}

        <ToggleList type="button" onClick={() => setIsShowList(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
          >
            <path d="M7.5 6a.75.75 0 0 1 .75-.75h12a.75.75 0 1 1 0 1.5h-12A.75.75 0 0 1 7.5 6Zm12.75 5.25h-12a.75.75 0 1 0 0 1.5h12a.75.75 0 0 0 0-1.5Zm0 6h-12a.75.75 0 1 0 0 1.5h12a.75.75 0 0 0 0-1.5ZM4.125 4.875a1.128 1.128 0 0 0-1.039.694A1.123 1.123 0 0 0 5.06 6.625a1.123 1.123 0 0 0-.935-1.75Zm0 6a1.128 1.128 0 0 0-1.039.694 1.123 1.123 0 0 0 1.974 1.056 1.123 1.123 0 0 0-.935-1.75Zm0 6a1.128 1.128 0 0 0-1.039.694 1.123 1.123 0 0 0 1.974 1.056 1.123 1.123 0 0 0-.935-1.75Z" />
          </svg>
        </ToggleList>

        {isShowList && (
          <ListContainer>
            <ListHeader>
              <span className="paragraph-small">Show:</span>
              <SelectedFilter>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width={16}
                  height={16}
                >
                  <path
                    fill="currentColor"
                    d="m14.104 4.354-8 8a.492.492 0 0 1-.545.109.482.482 0 0 1-.162-.109l-3.5-3.5a.5.5 0 1 1 .707-.707l3.146 3.147 7.647-7.647a.498.498 0 0 1 .707 0 .5.5 0 0 1 0 .707Z"
                  />
                </svg>
                <span className="paragraph-small">Retail</span>
              </SelectedFilter>
            </ListHeader>

            <OverlayScrollbarsComponent
              options={{
                scrollbars: { autoHide: 'leave', theme: 'os-theme-light' },
              }}
              defer
            >
              <List>
                {locations.map((loc) => (
                  <MapListItem
                    key={loc.num}
                    location={loc}
                    onMapView={(location) => {
                      setSelectedMarker(location);
                      if (window.innerWidth < 1024) {
                        setIsShowList(false);
                      }
                      handleScroll();
                    }}
                  />
                ))}
              </List>
            </OverlayScrollbarsComponent>

            <ToggleList
              className="--desktop"
              type="button"
              onClick={() => setIsShowList(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M19.281 18.22a.766.766 0 0 1 .219.53.747.747 0 0 1-.75.751.761.761 0 0 1-.531-.22L12 13.061l-6.22 6.22a.747.747 0 0 1-1.061 0 .75.75 0 0 1 0-1.061L10.94 12 4.719 5.781A.75.75 0 1 1 5.78 4.72L12 10.94l6.219-6.22a.75.75 0 0 1 1.062 1.061L13.06 12l6.221 6.22Z" />
              </svg>
            </ToggleList>

            <ToggleList
              className="--mobile"
              type="button"
              onClick={() => setIsShowList(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M21.461 4.658a.754.754 0 0 0-.643-.136l-5.731 1.433-5.751-2.876a.755.755 0 0 0-.518-.057l-6 1.5a.752.752 0 0 0-.568.728v13.5a.752.752 0 0 0 .932.728l5.731-1.433 5.751 2.876a.757.757 0 0 0 .518.057l6-1.5a.752.752 0 0 0 .568-.728V5.25a.749.749 0 0 0-.289-.592ZM9.75 4.963l4.5 2.25v11.824l-4.5-2.25V4.963Zm-6 .873 4.5-1.125v11.953l-4.5 1.125V5.836Zm16.5 12.328-4.5 1.125V7.336l4.5-1.125v11.953Z" />
              </svg>
            </ToggleList>
          </ListContainer>
        )}
      </MapContainer>
    </Root>
  );
};
